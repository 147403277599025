.wrapper {
    width: 100%;
    height: 70px;
    display: flex;
    padding: 12px 10px;
    box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, .15);
    align-items: center;
    background-color: #ffffff;
    padding-left: 80px;
}



.search {
    height: 100%;
    display: flex;
    align-items: center;
}

.typeContainer {
    height: 100%;
}

.typeOfSearch {
    border: 2px solid #fd0053;
    height: 100%;
    margin: 0 0 0 10px;
    border-radius: 4px;
}

.typeSelect {
    color: #000000;
    height: 100%;
    padding: 0 14px;
    font-size: 15px;
    font-weight: 700;
}

.rightSide {
    height: 100%;
    margin: 0 0 0 auto;
    display: flex;
}

.cart {
    width: 40px;
    cursor: pointer;
    height: 100%;
    margin: 0 14px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heart {
    width: 40px;
    cursor: pointer;
    height: 100%;
    margin: 0 14px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg path {
        fill: rgb(146, 146, 157);
    }
}

.notification {
    width: 40px;
    cursor: pointer;
    height: 100%;
    margin: 0 14px 0 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.user {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.userAvatar {
    width: 32px;
    height: 32px;
    margin: 0 10px 0 0;
    border-radius: 10px;
    background-color: #fc5a5a;

    & img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 9px;
    }
}

.userName {
    color: #171725;
    font-size: 14px;
    font-weight: 600;
}

.grayWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 3.625rem;
    background-color: #FAFAFA;
}

.img {
    margin-left: 2rem;
}

.form {
    flex: 1;
    display: flex;
    align-items: center;
}

.input {
    flex: 1;
    font-size: 1.3rem;
    margin-left: 1.5rem;
}

.cancelIcon {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
}

.profileMenu {
    top: 74px !important;
    width: auto;
}


html[dir="rtl"] .wrapper{
    padding-right: 80px;
    padding-left: 0px;

    .rightSide {
        margin: 0 auto 0 0;
    }

    .userAvatar {
        margin: 0 0 0 10px;
    }

    .user {
        padding-left: 10px !important;
    }
}

@media (max-width: 576px) {
    .wrapper{
        padding-left: 10px;
    }
    .wrapper :global .MuiSelect-selectMenu {
        margin: 0;
    }

    .search {
        max-width: 70px;
        margin-right: 10px;
    }

    .search :global svg {
        user-select: none;
        -webkit-user-drag: none;
    }

    .search-active {
        max-width: 100%;
        margin-right: 12px;
    }
}