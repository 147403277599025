.wrapper {
    width: 70px;
    z-index: 1;
    position: absolute;
    min-width: 70px;
    background: #ffffff;
    box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, .15);
    height: 100%;
}

.logo {
    height: 70px;
    display: flex;
    padding: 7px;
    box-sizing: border-box;
    align-items: center;
}

.menu {
    top: 0px;
    margin: 52px 0 0;
    display: flex;
    position: sticky;
    flex-direction: column;
}

.menuItem {
    width: 100%;
    height: 52px;
    display: flex;
    position: relative;
    transition: .3s;
    align-items: center;
    justify-content: center;

    & > div {
        top: 50%;
        right: 0;
        width: auto;
        display: none;
        padding: 10px 20px;
        z-index: 99;
        position: absolute;
        transform: translate(100%, -50%);
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(34, 60, 80, 0.17);
        transition: .1s ease-in-out;
        white-space: nowrap;
        border-radius: 12px;

        & div {
            top: 90%;
            left: -8px;
            width: 10px;
            height: 10px;
            position: absolute;
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            transform: rotate(-90deg) translateX(100%);
            background: #FFFFFF;
        }
    }

    & svg {
        fill: #92929d;
        width: 24px;
    }

    &:hover {
        background: #ffdde8;

        & svg {
            fill: #fd0053;
        }
    }

    &:hover > div {
        display: block;
    }
}

.active {
    background: #ffdde8;

    & svg {
        fill: #fd0053;
    }
}

@media (max-width: 576px) {
    .wrapper {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        z-index: 9999;
    }

    .wrapper .logo {
        flex-basis: 20%;
        height: auto;
        margin: 0 10px;
    }

    .menu {
        flex-direction: row;
        margin: 0;
        justify-content: space-between;
        width: 100%;
    }
}
