html {
    height: 100%;
    font-size: 1.631vmin;
}

@media (max-width: 576px) {
    html {
        font-size: 20px;
    }

    // ziv
    .LeftMenu_wrapper__vVacf {
        z-index: 10;
    }
}

body {
    margin: 0;
    font-family: Poppins !important;
    font-weight: 400;
    font-size: 10px;
    height: 100%;
    overflow-x: hidden;
    color: #131314;
}

#root {
    height: 100%;
    background-color: "white";
}

p {
    margin: 0;
    text-align: start;
}

input,
textarea {
    font-family: Poppins;
    font-size: 16px;
}

img {
    user-select: none;
}

svg {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #131314;
}


[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: #a8a9aa;
}

.App {
    text-align: center;
    height: 100%;
    background-color: white;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

span {
    text-align: start !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

//ziv
@media (min-width: 576px) {

    .makeStyles-content-10 {
        padding: 36px 90px !important;
    }

    .Header_wrapper__1VIoq {
        padding-right: 80px;
    }
}

.Header_wrapper__1VIoq>* {
    margin-left: 10px;
}

.Header_userAvatar__rFYbd {
    margin-left: 10px;
}

.makeStyles-form-15 {
    margin-right: 10px;

}