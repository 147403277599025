[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}
[type="file"] + label {
    border: none;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    width: 200px;
    height: 200px;
}

.ReactCrop > div > img {
    max-width: 100%;
    max-height: 100%;
}
